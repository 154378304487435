import { css } from "@linaria/core";
import { Profile } from "../models";
import { useStore } from "../utils/store";
import { OtpPageHeader } from "./OtpPageHeader";
import { OtpRow } from "./OtpRow";

const styles = {
  page: css`
    height: 100%;
  `,
};

type OtpRowProps = {
  profile: Profile;
};

export function OtpPage({ profile }: OtpRowProps) {
  const [store] = useStore();
  const otps = store.value.otps.filter((otp) => otp.profileId === profile.id);
  return (
    <div className={styles.page}>
      <OtpPageHeader profile={profile} />
      <div>
        {otps.map((otp) => (
          <OtpRow otp={otp} />
        ))}
        {!otps.length ? <p>No TOTPs added to {profile.name}</p> : <></>}
      </div>
    </div>
  );
}
