import { css } from "@linaria/core";
import { useEffect } from "preact/hooks";

import { Slider } from "../components/Slider";
import { useStore } from "../utils/store";
import { OtpPage } from "../components/OtpPage";
import { BottomNav } from "../components/BottomNav";
import { ProfileId } from "../models";

const styles = {
  screen: css`
    height: 100vh;
    display: flex;
    flex-direction: column;
  `,
};

type OtpListProps = {
  profileId: ProfileId;
};

export function OtpList({ profileId }: OtpListProps) {
  const [store, setStore] = useStore();
  const { profiles } = store.value;
  const index = profiles.findIndex((profile) => profile.id === profileId);

  // Select the first page if none selected
  useEffect(() => {
    if (index === -1) {
      setStore({
        pageProps: {
          profileId: profiles[0].id,
        },
      });
    }
  }, [index, profiles]);

  return (
    <div className={styles.screen}>
      <Slider
        index={index}
        pages={profiles.map((profile) => (
          <OtpPage profile={profile} />
        ))}
      />
      <BottomNav
        profiles={profiles}
        selectedProfileId={profileId}
        onPress={(newProfileId) =>
          setStore({ pageProps: { profileId: newProfileId } })
        }
      />
    </div>
  );
}
