import { css } from "@linaria/core";
import * as COLORS from "../colors";
import {
  CameraDevice,
  Html5Qrcode,
  Html5QrcodeScannerState,
} from "html5-qrcode";
import { useEffect, useState } from "preact/hooks";
import { TOTP, URI as OtpUri } from "otpauth";
import { useStore } from "../utils/store";
import { NamedIcon } from "./NamedIcon";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  `,
  squareContainer: css`
    width: 20em;
    height: 20em;
    overflow: hidden;
    border-radius: 1em;
    border: 1px ${COLORS.black} solid;
  `,
  video: css`
    object-fit: cover;
    width: 20rem;
    aspect-ratio: 1;
  `,
  button: css`
    background: none;
    border: none;
    opacity: 0.7;
    transition: opacity ease-out 100ms;
    padding: 0.5rem;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &:active {
      opacity: 0.65;
      cursor: pointer;
    }
  `,
};

const READER_ID = "reader";

type ScannerProps = {
  onNewOtp: (otp: TOTP) => void;
};

export function Scanner({ onNewOtp }: ScannerProps) {
  const [scanner, setScanner] = useState<Html5Qrcode>();

  console.log(Html5QrcodeScannerState);
  console.log("Scanner state", scanner?.getState());

  const [store, setStore] = useStore();
  const [cameras, setCameras] = useState<CameraDevice[]>([]);

  const onScanSuccess = (decodedText: string) => {
    // Figure out if this is an OTP url
    let otp: TOTP;
    try {
      const url = new URL(decodedText);
      if (url.protocol !== "otpauth:") {
        throw Error("Invalid protocol");
      }
      if (!url.pathname.startsWith("//totp/")) {
        throw Error("Invalid protocol");
      }
      // We know this will be a TOTP and not a HOTP from the path above
      otp = OtpUri.parse(decodedText) as TOTP;
    } catch (err) {
      // Not an OTP url
      console.warn("Found invalid TOTP", err);
      return;
    }

    onNewOtp(otp);
  };

  // Switch to the next camera
  const onNextCamera = () => {
    const currentIndex = cameras.findIndex(
      (cam) => cam.id === store.value.currentCameraId
    );
    const nextCameraIndex = (currentIndex + 1) % cameras.length;
    const nextCamera = cameras[nextCameraIndex];
    console.log("Switching to camera:", nextCamera);
    setStore({
      currentCameraId: nextCamera.id,
    });
  };

  // Create the scanner instance on mount
  useEffect(() => {
    if (!scanner) {
      setScanner(new Html5Qrcode(READER_ID));

      const videoEl = document
        .getElementById(READER_ID)
        ?.querySelector("video");
      if (videoEl) {
        videoEl.classList.add(styles.video);
      }
    }
  }, [scanner]);

  // Stop any streams on unmount
  useEffect(() => {
    return async () => {
      console.log("Stopping camera2");
      await scanner?.stop().catch((err) => {
        console.log("Error stopping2", err);
      });
    };
  }, []);

  // Start the camera stream when the current camera changes
  useEffect(() => {
    (async () => {
      if (scanner) {
        const scannerState = scanner.getState();
        const needsToStop =
          scannerState === Html5QrcodeScannerState.SCANNING ||
          scannerState === Html5QrcodeScannerState.PAUSED;
        if (needsToStop) {
          // Stop any currently running camera stream.
          console.log("Stopping camera");
          try {
            await scanner.stop();
          } catch (err) {
            console.log("Error stopping", err);
          }
        }

        await scanner.start(
          store.value.currentCameraId ?? { facingMode: "environment" },
          { fps: 10, aspectRatio: 1 },
          onScanSuccess,
          () => {}
        );
      }
    })();
  }, [store.value.currentCameraId, scanner]);

  // Get the list of cameras on mount
  useEffect(() => {
    (async () => {
      if (scanner) {
        const available = await Html5Qrcode.getCameras();
        setCameras(available);
        if (!store.value.currentCameraId) {
          onNextCamera();
        }
        console.log("Cameras:", available);
      }
    })();
  }, [scanner]);

  return (
    <div className={styles.container}>
      <div className={styles.squareContainer}>
        <div id={READER_ID} width="650px"></div>
      </div>
      {cameras.length > 1 ? (
        <button onClick={onNextCamera} className={styles.button}>
          <NamedIcon name="CameraReverse" />
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
