import { css } from "@linaria/core";

const styles = {
  bar: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;

    padding: 1em 2em;
  `,
  title: css`
    font-size: 20pt;
    font-family: sans-serif;
    font-weight: 200;
    width: 100%;
  `,
  leftSpacer: css`
    width: 2rem;
  `,
};

type HeaderProps = {
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
  content?: React.ReactNode;
};

export function Header({ leftButton, rightButton, content }: HeaderProps) {
  return (
    <div className={styles.bar}>
      {leftButton ?? <div className={styles.leftSpacer}></div>}
      {typeof content === "string" ? (
        <h1 className={styles.title}>{content}</h1>
      ) : (
        content
      )}
      {rightButton}
    </div>
  );
}
