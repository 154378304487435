import { css } from "@linaria/core";
import { Otp, OtpAlgorithm, ProfileId } from "../models";
import { useStore } from "../utils/store";
import { Scanner } from "../components/Scanner";
import { Header } from "../components/Header";
import { NamedIcon } from "../components/NamedIcon";
import { TOTP } from "otpauth";

const styles = {
  screen: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 1em;
  `,
  headerButton: css`
    background: none;
    border: none;
    opacity: 0.7;
    transition: opacity ease-out 100ms;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &:active {
      opacity: 0.65;
      cursor: pointer;
    }
  `,
};

type AddCodeProps = {
  profileId: ProfileId;
};

export function AddCode({ profileId }: AddCodeProps) {
  const [store, setStore] = useStore();
  const { profiles } = store.value;
  const profile = profiles.find((profile) => profile.id === profileId)!;

  const goBack = () => {
    setStore({
      page: "settings",
      pageProps: { profileId },
    });
  };

  const onScan = (totp: TOTP) => {
    const otp: Otp = {
      profileId,
      name: totp.label,
      issuer: totp.issuer,
      algorithm: totp.algorithm.toLowerCase() as OtpAlgorithm,
      secret: totp.secret.base32,
    };
    setStore({
      otps: [...store.value.otps, otp],
      page: "otpList",
      pageProps: { profileId },
    });
  };

  return (
    <>
      <Header
        content={`${profile.name} - Scan QR Code`}
        leftButton={
          <button className={styles.headerButton} onClick={goBack}>
            <NamedIcon name="ArrowBack" />
          </button>
        }
      />
      <div className={styles.screen}>
        <Scanner onNewOtp={onScan} />
      </div>
    </>
  );
}
