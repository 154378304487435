import * as icons from "react-ionicons";

export type IconName = keyof typeof icons;
export const iconNames = Object.keys(icons).filter(
  (x) => !x.endsWith("Outline") && !x.endsWith("Sharp")
) as IconName[];

type NamedIconProps = {
  name: IconName;
} & Record<string, any>;

export function NamedIcon({ name, ...otherProps }: NamedIconProps) {
  const Icon = icons[name] ?? icons["Help"];
  return <Icon {...otherProps} />;
}
