import { css } from "@linaria/core";
import { useEffect, useState } from "preact/hooks";

type ProgressBarProps = {
  /** How long a full period is in seconds */
  length: number;
  /** The initial progress in seconds */
  progress: number;
  /** The color of the bar */
  color: string;
};

const styles = {
  container: css`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `,
  bar: css`
    height: 0.25em;
  `,
};

export function ProgressBar({ length, progress, color }: ProgressBarProps) {
  const [width, setWidth] = useState(100 * (progress / length));

  const backgroundColor = color ?? "black";

  useEffect(() => {
    setWidth(100 * (progress / length));
  }, [progress]);

  useEffect(() => {
    requestAnimationFrame(() => {
      setWidth(0);
    });
  }, [width]);

  const isSetup = width === 0;

  return (
    <div className={styles.container}>
      <div
        className={styles.bar}
        style={
          isSetup
            ? {
                width: `${width}%`,
                transition: `width linear ${progress}s`,
                backgroundColor,
              }
            : {
                width: `${width}%`,
                backgroundColor,
              }
        }
      ></div>
    </div>
  );
}
