import { css } from "@linaria/core";
import { ReactNode } from "preact/compat";

const styles = {
  container: css`
    overflow: hidden;
    height: 100%;
  `,
  scrollContainer: css`
    display: flex;
    flex-direction: row;

    transition: ease-out 0.2s transform;
  `,
  page: css`
    height: 100%;
    width: 100vw;
  `,
};

type SliderProps = {
  pages: ReactNode[];
  index: number;
};

export function Slider({ pages, index }: SliderProps) {
  const totalWidth = `${pages.length * 100}vw`;
  const scrollPosition = `${index * 100}vw`;

  return (
    <div className={styles.container}>
      <div
        className={styles.scrollContainer}
        style={{
          width: totalWidth,
          transform: `translate(-${scrollPosition}, 0)`,
        }}
      >
        {pages.map((page) => (
          <div className={styles.page}>{page}</div>
        ))}
      </div>
    </div>
  );
}
