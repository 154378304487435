import { css } from "@linaria/core";
import Select from "react-select";

import { Profile, ProfileId } from "../models";
import { useStore } from "../utils/store";
import { Header } from "../components/Header";
import { IconName, NamedIcon, iconNames } from "../components/NamedIcon";
import * as COLORS from "../colors";
import { id } from "../utils/ids";
import { Button } from "../components/Button";

const styles = {
  page: css`
    display: flex;
    flex-direction: column;

    padding: 1em 2em;

    & label {
      font-family: sans-serif;
      margin-bottom: 0.25rem;
      margin-top: 1rem;
      color: ${COLORS.primary};
    }
    & label:first-child {
      margin-top: 0;
    }
  `,
  headerButton: css`
    background: none;
    border: none;
    opacity: 0.7;
    transition: opacity ease-out 100ms;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &:active {
      opacity: 0.65;
      cursor: pointer;
    }
  `,
  input: css`
    max-width: 20rem;
    padding: 0.5em 0.5rem;
    border-radius: 0.5em;
    border: 1px solid ${COLORS.grey};
  `,
  selectContainer: css`
    max-width: 5rem;
  `,
  selectControl: css`
    border-radius: 0.5em !important;
    border: 1px solid ${COLORS.grey} !important;
  `,
};

type SettingsProps = {
  profileId: ProfileId;
};

export function Settings({ profileId }: SettingsProps) {
  const [store, setStore] = useStore();

  const thisProfile = store.value.profiles.find((p) => p.id === profileId)!;

  const updateName = (newName: string) => {
    setStore({
      profiles: store.value.profiles.map((profile) => {
        if (profile === thisProfile) {
          return {
            ...thisProfile,
            name: newName,
          };
        }
        return profile;
      }),
    });
  };

  type IconOption = {
    value: IconName;
  };
  const iconOptions: IconOption[] = iconNames.map((name) => {
    return {
      value: name as IconName,
    };
  });

  const updateIcon = (newIcon: IconName) => {
    setStore({
      profiles: store.value.profiles.map((profile) => {
        if (profile === thisProfile) {
          return {
            ...thisProfile,
            icon: newIcon,
          };
        }
        return profile;
      }),
    });
  };

  const goToScanner = () => {
    setStore({
      page: "addCode",
      pageProps: { profileId: thisProfile.id },
    });
  };
  const goBack = () => {
    setStore({
      page: "otpList",
      pageProps: { profileId: thisProfile.id },
    });
  };

  const addNewProfile = () => {
    const newProfile: Profile = {
      id: id("profile"),
      name: "New Profile",
      icon: iconNames[Math.floor(Math.random() * iconNames.length)],
    };
    setStore({
      page: "settings",
      pageProps: { profileId: newProfile.id },
      profiles: [...store.value.profiles, newProfile],
    });
  };

  const deleteProfile = () => {
    if (store.value.profiles.length === 1) {
      alert("You can't remove the last group!");
      return;
    }

    if (
      !confirm(
        [
          `Are you sure you want to delete ${thisProfile.name}?`,
          "",
          "This will also delete all associated OTP codes, so be careful!",
        ].join("\n")
      )
    ) {
      return;
    }

    const firstProfile = store.value.profiles[0];

    setStore({
      page: "otpList",
      pageProps: { profileId: firstProfile.id },
      profiles: store.value.profiles.filter((x) => x.id !== thisProfile.id),
      otps: store.value.otps.filter((x) => x.profileId !== thisProfile.id),
    });
  };

  return (
    <>
      <Header
        content={`${thisProfile.name} - Settings`}
        leftButton={
          <button className={styles.headerButton} onClick={goBack}>
            <NamedIcon name="ArrowBack" />
          </button>
        }
        rightButton={
          <button className={styles.headerButton} onClick={goToScanner}>
            <NamedIcon name="AddCircle" />
          </button>
        }
      />
      <div className={styles.page}>
        <label for="nameInput">Group Name</label>
        <input
          id="nameInput"
          className={styles.input}
          type="text"
          value={thisProfile.name}
          onChange={(e) => updateName((e.target as HTMLInputElement).value!)}
        />
        <label for="iconInput">Group Icon</label>
        <Select<IconOption>
          id="iconInput"
          classNames={{
            container: () => styles.selectContainer,
            control: () => styles.selectControl,
          }}
          defaultValue={iconOptions[0]}
          value={iconOptions.find((opt) => opt.value === thisProfile.icon)}
          options={iconOptions}
          formatOptionLabel={(option: IconOption) => (
            <NamedIcon name={option.value} />
          )}
          onChange={(option: IconOption) => updateIcon(option.value)}
          isSearchable
        />
        <label>Delete "{thisProfile.name}" group</label>
        <Button title={`Delete group`} onPress={deleteProfile} />
        <label>Create a new group</label>
        <Button title="Create" onPress={addNewProfile} />
      </div>
    </>
  );
}
