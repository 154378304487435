import { useStore } from "../utils/store";
import { AddCode } from "./AddCode";
import { OtpList } from "./OtpList";
import { Settings } from "./Settings";

export function Router() {
  const [store] = useStore();
  const { page, pageProps } = store.value;

  switch (page) {
    case "otpList":
      return <OtpList {...pageProps} />;
    case "settings":
      return <Settings {...pageProps} />;
    case "addCode":
      return <AddCode {...pageProps} />;
    default:
      return <p>Not Found</p>;
  }
}
