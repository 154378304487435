import { useSignal } from "@preact/signals";
import { TOTP } from "otpauth";

import * as COLORS from "../colors";
import { Otp } from "../models";
import { OTP_DIGITS, OTP_SECONDS } from "../constants";
import { ProgressBar } from "./ProgressBar";
import { css } from "@linaria/core";

const styles = {
  row: css`
    height: 5.25rem;
    width: 100%;
    border: none;
    padding: 0;
    text-align: left;
    border-top: 1px solid ${COLORS.secondary};
    background-color: ${COLORS.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 0.25rem;
    transition: opacity ease-out 0.2s;

    &:last-child {
      border-bottom: 1px solid ${COLORS.secondary};
    }

    &:active {
      opacity: 0.5;
    }
  `,
  content: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1em;
  `,
  leftSection: css`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    min-width: 0;
  `,
  name: css`
    font-family: sans-serif;
    color: ${COLORS.black};
    font-size: 12pt;
    font-weight: 200;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
  `,
  issuer: css`
    font-family: sans-serif;
    color: ${COLORS.primary};
    font-size: 10pt;
    font-weight: 200;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
  `,
  rightSection: css`
    font-weight: bold;
    font-size: 20pt;
    white-space: nowrap;
    font-family: monospace;
    letter-spacing: 0.25rem;
    color: ${COLORS.black};
    padding-right: 1em;
  `,
  placeHolderProgressBar: css`
    width: 100%;
    height: 0.25rem;
  `,
};

type OtpRowProps = {
  otp: Otp;
};

export function OtpRow({ otp }: OtpRowProps) {
  const totp = new TOTP({
    issuer: otp.issuer,
    label: otp.name,
    algorithm: otp.algorithm.toUpperCase(),
    digits: OTP_DIGITS,
    period: OTP_SECONDS,
    secret: otp.secret,
  });
  const showCode = useSignal(false);

  const code = useSignal(totp.generate());
  const period = totp.period * 1000;
  const formattedCode = `${code.value.slice(0, 3)} ${code.value.slice(3)}`;

  const nextCodeAt = period - (new Date().getTime() % period);

  const onShow = () => {
    const uncoveredAt = new Date().getTime();
    const nextCodeAt = period - (new Date().getTime() % period);
    showCode.value = true;

    // Hide this OTP again when it expires, unless you have tapped it within
    // 10 seconds of it expiring - then give an extra period
    const hideAt = nextCodeAt < 10_000 ? nextCodeAt + period : nextCodeAt;
    console.log({ uncoveredAt, nextCodeAt, hideAt });
    setTimeout(() => {
      showCode.value = false;
    }, hideAt);

    // Re-render OTP when time expires - this may be before we hide it
    setTimeout(() => {
      code.value = totp.generate();
    }, nextCodeAt);
  };

  return (
    <button
      className={styles.row}
      onClick={showCode.value ? undefined : onShow}
    >
      <div className={styles.content}>
        <div className={styles.leftSection}>
          <div className={styles.name}>{totp.label}</div>
          <div className={styles.issuer}>{totp.issuer}</div>
        </div>
        <div className={styles.rightSection}>
          {showCode.value ? formattedCode : "--- ---"}
        </div>
      </div>
      {showCode.value ? (
        <ProgressBar
          length={totp.period}
          progress={nextCodeAt / 1000}
          color={COLORS.primary}
        />
      ) : (
        <div className={styles.placeHolderProgressBar}></div>
      )}
    </button>
  );
}
