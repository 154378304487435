import { css } from "@linaria/core";
import { Profile, ProfileId } from "../models";
import * as COLORS from "../colors";
import { IconName, NamedIcon } from "./NamedIcon";

const styles = {
  bar: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  navButton: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 5em;

    background: ${COLORS.secondary};
    border: none;
    opacity: 0.85;
    transition: all ease-out 100ms;
    border-bottom: 0.25em solid ${COLORS.secondary};

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &:active {
      opacity: 0.7;
      cursor: pointer;
      border-color: ${COLORS.primary};
    }

    &:disabled {
      color: ${COLORS.black};
      border-color: ${COLORS.primary};

      &:hover {
        opacity: 0.85;
        cursor: auto;
      }
      &:active {
        opacity: 0.85;
        cursor: auto;
      }
    }
  `,
};

type BottomNavProps = {
  profiles: Profile[];
  selectedProfileId: ProfileId;
  onPress: (profile: ProfileId) => void;
};

export function BottomNav({
  profiles,
  selectedProfileId,
  onPress,
}: BottomNavProps) {
  return (
    <div className={styles.bar}>
      {profiles.map((profile) => {
        const selected = profile.id === selectedProfileId;

        return (
          <button
            className={styles.navButton}
            onClick={selected ? undefined : () => onPress(profile.id)}
            disabled={selected}
          >
            <NamedIcon
              name={
                selected ? profile.icon : (`${profile.icon}Outline` as IconName)
              }
            />
            {profile.name}
          </button>
        );
      })}
    </div>
  );
}
