import { signal, effect, computed } from "@preact/signals";
import { Otp, Profile } from "../models";
import { id } from "./ids";

type StoreState = {
  loaded: boolean;
  profiles: Profile[];
  otps: Otp[];
  page: string;
  pageProps: any;
  currentCameraId: string | undefined;
};

const DEFAULT_WORK_PROFILE_ID = id("profile");
const DEFAULT_HOME_PROFILE_ID = id("profile");
const DEFAULT_STATE: StoreState = {
  loaded: false,
  profiles: [
    {
      name: "Work",
      id: DEFAULT_WORK_PROFILE_ID,
      icon: "Briefcase",
    },
    {
      name: "Personal",
      id: DEFAULT_HOME_PROFILE_ID,
      icon: "Star",
    },
  ],
  otps: [],
  page: "otpList",
  pageProps: {},
  currentCameraId: undefined,
};
const KEYS_TO_PERSIST: (keyof StoreState)[] = ["otps", "profiles"];

/** The store state */
export const state = signal<StoreState>(DEFAULT_STATE);
/**
 * Should we persist state? Only after we have loaded the state for the first
 * time.
 */
const shouldPersistState = computed(() => state.value.loaded);
/** The store state to persist in localstorage */
const stateToPersist = computed(() =>
  Object.fromEntries(
    Object.entries(state.value).filter(([key]) =>
      KEYS_TO_PERSIST.includes(key as keyof StoreState)
    )
  )
);

export const setState = (update: Partial<StoreState>) => {
  state.value = { ...state.value, ...update };
};

export const useStore = () => [state, setState] as const;

// Load the state from the store on page load
window.addEventListener("load", () => {
  const strState = window.localStorage.getItem("ootp_storage");
  if (strState) {
    state.value = { ...DEFAULT_STATE, loaded: true, ...JSON.parse(strState) };
  } else {
    setState({ loaded: true });
  }
});

// Write the state when it changes
effect(() => {
  if (shouldPersistState.value) {
    window.localStorage.setItem(
      "ootp_storage",
      JSON.stringify(stateToPersist.value)
    );
  }
});
