import { SettingsSharp } from "react-ionicons";
import { css } from "@linaria/core";
import { Profile } from "../models";
import { useStore } from "../utils/store";
import { Header } from "./Header";

const styles = {
  settingsButton: css`
    background: none;
    border: none;
    opacity: 0.7;
    transition: opacity ease-out 100ms;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &:active {
      opacity: 0.65;
      cursor: pointer;
    }
  `,
};

type HeaderProps = {
  profile: Profile;
};

export function OtpPageHeader({ profile }: HeaderProps) {
  const [, setStore] = useStore();

  const goToSettings = () =>
    setStore({
      page: "settings",
      pageProps: { profileId: profile.id },
    });

  return (
    <Header
      content={profile.name}
      rightButton={
        <button className={styles.settingsButton} onClick={goToSettings}>
          <SettingsSharp />
        </button>
      }
    />
  );
}
